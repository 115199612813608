import logo from './logo.svg';
import './App.css';
import React, { useState } from 'react';
import { app } from "./firebase/firebase";

const baseURL = 'https://www.tiktok.com/oembed/?url=';


async function fetchTiktokEmbed(url) {
  const response = await fetch(baseURL + url);
  const json = await response.json();
  return json;
}

function App() {

  const [tiktokURL, setTikTokURL] = useState("https://www.tiktok.com/@avesleroyyy/video/7049009332574326063?_d=secCgYIASAHKAESPgo8Hg7Mtulqf6SEYgVjsL9%2BAsSJObq%2FzRp%2BgPRW6WKnxRpBcdhgs8EB0LYAQ7BJuGC4Zvcu8S%2FbBK1lnRIZGgA%3D&checksum=4fce6287b90441795cb8c5055bc086381f3132ded955b90b7f666792c9edd31e&language=en&preview_pb=0&sec_user_id=MS4wLjABAAAAFX4ASBYWW8lv5MXkugQEleASXtqi-zm24qDoQoe9Ho9qTWOfZZgC-PBDf0yvM9Zv&share_app_id=1233&share_item_id=7049009332574326063&share_link_id=5E37FB03-AA4B-44F8-BD3B-3F997422A232&source=h5_m&timestamp=1641267725&tt_from=copy&u_code=dekck4hcdegc5l&user_id=6878867156558627841&utm_campaign=client_share&utm_medium=ios&utm_source=copy&_r=1");
  const html = fetchTiktokEmbed(tiktokURL).then(json => {console.log(json)});

  return (
    <>
    <h1><span className='red'>tiktok</span><span className='blue'>comments</span></h1>
    {/* <input onChange={(text) => {console.log(text.target.value)}}></input> */}
    <div className='tiktok viewer' dangerouslySetInnerHTML={html.html}></div>
    </>
  );
}

export default App;
